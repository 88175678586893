import styles from "style/pages/market/previewSidebar/previewInfo.module.scss";
import dayjs, { ManipulateType } from "dayjs";
import cx from "classnames";
import {
  AlertIcon,
  DataDestroyIcon,
  DataEncodingIcon,
  DataPrivacyIcon,
  DataPurposeIcon,
  DataSecurityIcon,
  DataPrivacyDefaultIcon,
  DataThirdPartyIcon,
  DataInjectionIcon,
  DataInsuranceIcon,
  DataSecuritiesIcon,
  DataMedicalRecordIcon,
  DataBankIcon,
  DataShoppingIcon,
  DataGoogleYoutubeIcon,
  DataCardIcon,
  DataInterestIcon,
  DataEMRIcon,
  DataExerciseIcon,
} from "assets/svgs/market";
import { TMarketPrivacyData, TMarketProductData } from "types/market";
import { syncProgressPeriod } from "utils/market";

type TProps = {
  infoData: {
    rewardPolicyAmount: number;
    dataPurpose: string[];
    privacyState: TMarketPrivacyData;
    maxIssuable: number;
    collectTarget: string[];
    collectPeriod: string;
    startDate?: string;
    endDate?: string;
    product: TMarketProductData;
    transactionTime?: string;
  };
};
export default function PreviewInfo({ infoData }: TProps) {
  const {
    privacyState,
    dataPurpose,
    maxIssuable,
    collectTarget,
    rewardPolicyAmount,
    collectPeriod,
    startDate,
    endDate,
    product,
    transactionTime,
  } = infoData;
  const { personalIdentification: identify, thirdPartyStatus, company, provisionPurpose } = privacyState;

  const collectTargetIconMap = (target: string) => {
    if (target === "진료 및 투약") return <DataInjectionIcon width={60} height={60} />;
    if (target === "보험") return <DataInsuranceIcon width={60} height={60} />;
    if (target === "증권") return <DataSecuritiesIcon width={60} height={60} />;
    if (target === "건강검진") return <DataMedicalRecordIcon width={60} height={60} />;
    if (target === "은행 예적금") return <DataBankIcon width={60} height={60} />;
    if (target === "쇼핑") return <DataShoppingIcon width={60} height={60} />;
    if (target === "구글/유튜브") return <DataGoogleYoutubeIcon width={60} height={60} />;
    if (target === "카드") return <DataCardIcon width={60} height={60} />;
    if (target === "EMR") return <DataEMRIcon width={60} height={60} />;
    if (target === "운동") return <DataExerciseIcon width={60} height={60} />;
    return <DataInterestIcon width={60} height={60} />;
  };

  const isDateNPeriodSet = startDate && endDate && collectPeriod;
  const collectTime = Number(collectPeriod.slice(0, collectPeriod.length - 1));
  const collectUnit = collectPeriod.slice(-1);

  const collectStart = dayjs(startDate)
    .subtract(collectTime, collectUnit as ManipulateType)
    .add(transactionTime ? +transactionTime : 0, "hour");

  const collectEnd = dayjs(startDate)
    .add(transactionTime ? +transactionTime : 0, "hour")
    .subtract(1, "minute");

  const medicationRecordEndDate = isDateNPeriodSet ? collectEnd.subtract(2, "month") : null;
  const medicationRecordStartDate = isDateNPeriodSet ? collectStart.subtract(2, "month") : null;

  const EMRIdx = collectTarget.indexOf("EMR");

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabWrapper}>
        <div>상세정보</div>
        <div>기업정보</div>
      </div>
      <div className={styles.innerWrapper}>
        <div className={styles.infoIndex}>데이터 거래 기본 정보</div>
        <div className={styles.infoTopWrapper}>
          <div>
            <h4>거래대상</h4>
            아래 데이터를 보유하고 있는 마이디 사용자
          </div>
          <div>
            <h4>데이터 거래 항목</h4>
            <div className={styles.collectTarget}>
              {collectTarget.length ? (
                <div className={styles.gridContainer}>
                  {collectTarget.map((target, i) => (
                    <div key={target} className={cx(styles.gridItem, { [styles.emr]: EMRIdx === i })}>
                      <div>{collectTargetIconMap(target)}</div>
                      <p>{target === "EMR" ? `전자의무기록(EMR)` : target}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.collectTargetGuide}>{"수집 데이터를\n선택해 주세요"}</div>
              )}
            </div>
            {isDateNPeriodSet && (
              <small>
                {`${collectStart.format("YYYY.MM.DD HH:mm")} ~ ${collectEnd.format(
                  "YYYY.MM.DD HH:mm"
                )} (최근 ${collectPeriod.replace("w", "주").replace("M", "개월")}) 사이에 수집된 데이터에 한함`}
              </small>
            )}
            {collectTarget.includes("진료 및 투약") && isDateNPeriodSet && (
              <p className={styles.medication_info}>
                *진료 및 투약 데이터는 {medicationRecordStartDate?.format("YYYY.MM.DD")} ~{" "}
                {medicationRecordEndDate?.format("YYYY.MM.DD")} 사이에 수집된 데이터에 한함
              </p>
            )}
          </div>
          <div>
            <h4>모집 인원</h4>
            {maxIssuable
              ? `${maxIssuable.toLocaleString()}명 (선착순 마감)`
              : "목표 인원을 선택해 주세요 (선착순 마감)"}
          </div>
          <div>
            <h4>모집 마감일</h4>
            {startDate && endDate
              ? `~${syncProgressPeriod(dayjs(endDate)).format("YYYY.MM.DD")}`
              : "거래 진행 기간을 설정해 주세요"}
            <div>(선착순 마감시 조기종료 될 수 있습니다)</div>
          </div>
          <div>
            <h4>지급 포인트</h4>
            {rewardPolicyAmount ? `${rewardPolicyAmount.toLocaleString()}P` : "1인당 지급 포인트를 설정해 주세요"}
          </div>
        </div>
        <div className={styles.infoIndex}>데이터 거래 활용 정보</div>
        <div className={styles.infoBottomWrapper}>
          <h4>거래목적</h4>
          <div>
            <DataPurposeIcon />
            {dataPurpose.length ? dataPurpose.join(", ") : "거래 목적을 선택해 주세요"}
          </div>
          {identify || product.type !== "myd" ? (
            ""
          ) : (
            <h4 className={styles.blank}>개인식별정보 포함 여부를 선택해주세요</h4>
          )}
          {identify === "anonymous" ? (
            <>
              <h4>데이터 가명 처리</h4>
              <div>
                <DataPrivacyIcon />
                이름, 주민등록번호, 연락처, 상세일자정보, 그 외 식별 가능성이 있는 정보
              </div>
            </>
          ) : (
            identify && (
              <>
                <h4>일반 개인정보 수집</h4>
                <div>
                  <DataPrivacyDefaultIcon />
                  이름, 생년월일, 전화번호 3개 개인정보 항목 수집
                </div>
              </>
            )
          )}
          {thirdPartyStatus === 1 && (
            <>
              <h4>개인정보 제3자 제공</h4>
              <div>
                <DataThirdPartyIcon />

                <dl className={styles.privacy}>
                  <div>
                    <dt>제공받는자</dt>
                    <dd>{company || " "}</dd>
                  </div>

                  <div>
                    <dt>제공받는 목적</dt>
                    <dd>{provisionPurpose.length ? provisionPurpose.join(", ") : " "}</dd>
                  </div>

                  <div>
                    <dt>제공받는 항목</dt>
                    <dd>이름, 생년월일, 전화번호</dd>
                  </div>
                </dl>
              </div>
            </>
          )}
          <h4>데이터 암호화 전송</h4>
          <div>
            <DataEncodingIcon /> 전송구간 암호화 (TLS 1.3), 데이터 암호화 (AES 256), 데이터 무결성 (SHA 512)
          </div>
          <h4>데이터 관리</h4>
          <div className={styles.dataManage}>
            <div>
              <DataDestroyIcon /> 거래 후 1년간 보관 후 파기
            </div>
            <div>
              <DataSecurityIcon /> 법률에 따른 관리적 보안
            </div>
          </div>
        </div>
        <div className={styles.checkDealing}>
          <AlertIcon width={20} height={20} />
          거래 가능여부 확인하기
        </div>
        <div className={styles.dealingButton}>거래하기</div>
      </div>
    </div>
  );
}
