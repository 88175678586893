import { useRecoilState } from "recoil";

import { CheckInput } from "common/input";
import { collectDataList as defaultCollectDataList } from "constants/market";

import { marketTargetAtom } from "recoils/market";

import styles from "style/pages/market/createMarketPage/sectionTargetData/collectData.module.scss";

export default function CollectData() {
  const [{ collectData }, setTargetState] = useRecoilState(marketTargetAtom);

  const handleDataCheck = (target: string) => {
    collectData.includes(target)
      ? setTargetState((prev) => ({ ...prev, collectData: collectData.filter((data) => data !== target) }))
      : setTargetState((prev) => ({ ...prev, collectData: collectData.concat(target) }));
  };

  const groupCollectData = (category: "건강" | "금융" | "생활") => {
    if (category === "건강") {
      return defaultCollectDataList.filter(({ value }) =>
        ["medicalCheckup", "medicationRecord", "patientEMR", "exerciseResult"].includes(value)
      );
    }
    if (category === "금융") {
      return defaultCollectDataList.filter(({ value }) =>
        ["bankTransaction", "card", "securities", "insurance"].includes(value)
      );
    }
    if (category === "생활") {
      return defaultCollectDataList.filter(({ value }) =>
        ["googleYoutubeKeyword", "shoppingMallOrder"].includes(value)
      );
    }
    return [];
  };

  const formatCheckInput = (
    dataList: {
      label: string;
      value: string;
      disabled?: boolean;
    }[]
  ) => (
    <div className={styles.dataWrapper}>
      {dataList.map(({ label, value, disabled }) => {
        return (
          <CheckInput
            handleCheck={() => handleDataCheck(value)}
            name={label}
            text={label}
            value={collectData?.includes(value)}
            key={value}
            disabled={disabled}
          />
        );
      })}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.subTitle}>수집 데이터</div>

      <div className={styles.list}>
        <div className={styles.label}>건강</div> {formatCheckInput(groupCollectData("건강"))}
      </div>

      <div className={styles.list}>
        <div className={styles.label}>금융</div> {formatCheckInput(groupCollectData("금융"))}
      </div>

      <div className={styles.list}>
        <div className={styles.label}>생활</div> {formatCheckInput(groupCollectData("생활"))}
      </div>
    </div>
  );
}
