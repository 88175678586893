type TPayload = {
  marketTitleList: {
    exptype: {
      name: string;
      title: string[];
    }[];
  };
  titleType: string;
  dataType: string;
  point: string;
  intro?: string;
  maxIssuable: number;
};

export const transactionNaming = ({
  marketTitleList,
  titleType,
  dataType = "",
  intro,
  point,
  maxIssuable,
}: TPayload) => {
  const [titleListItem] = marketTitleList?.exptype.filter(({ name }) => name === titleType) || [];
  const { title: titleList } = titleListItem || {};

  const handleIntro = (value?: string) => {
    if (value === "선착순") return "[선착순]";
    if (value === "이벤트") return "[이벤트]";
    if (value === "목표 인원 수") return `[${maxIssuable.toLocaleString()}명]`;
    return "";
  };
  const replaceTitle = (title: string) => title.replace(/{\$POINT}/g, point).replace(/{\$TYPE}/g, dataType);

  const handledTitle = titleList?.map((title) => `${handleIntro(intro)} ${replaceTitle(title)}`.trim());
  return handledTitle;
};
