const LIMIT_PRICE = 10000;
const [TARGET_MIN_AGE, TARGET_MAX_AGE] = [14, 65];

const marketProducts = [
  {
    title: "마이디 - 올데이터 프리미엄",
    company: "snplab",
    selectPrivacy: false,
    type: "myd",
  },
];

const nonAnonymousMarketProducts = [
  ...marketProducts,
  {
    title: "마이디 - 올데이터 프리미엄(실명거래용)",
    company: "snplab",
    selectPrivacy: true,
    type: "myd",
  },
];

const genderList = [
  {
    label: "전체",
    value: "all",
  },
  {
    label: "여성",
    value: "female",
  },
  {
    label: "남성",
    value: "male",
  },
];

const mobileList = [
  {
    label: "필터없음",
    value: "all",
  },
  {
    label: "SKT",
    value: "SKT",
  },
  {
    label: "KT",
    value: "KT",
  },
  {
    label: "LGU+",
    value: "LGU+",
  },
  {
    label: "알뜰폰",
    value: "알뜰폰",
  },
];
const privacyDataList = [
  {
    label: "익명 데이터 거래",
    value: "anonymous",
  },

  // {
  //   label: "실명 데이터 거래",
  //   value: "nonAnonymous",
  //   disabled: true,
  // },
];

const collectDataList = [
  { label: "건강검진 데이터", value: "medicalCheckup" },
  { label: "병원진료 및 투약 데이터", value: "medicationRecord" },
  { label: "EMR 데이터", value: "patientEMR" },
  { label: "운동 데이터", value: "exerciseResult" },
  { label: "은행(예적금) 데이터", value: "bankTransaction" },
  { label: "카드 데이터", value: "card" },
  { label: "증권 데이터", value: "securities" },
  { label: "보험 데이터", value: "insurance" },
  { label: "Google/Youtube 검색 데이터", value: "googleYoutubeKeyword" },
  { label: "쇼핑 데이터", value: "shoppingMallOrder" },
];

const collectPeriodList = [
  { label: "최근 2주", value: "2w" },
  { label: "최근 1개월", value: "1M" },
  { label: "최근 3개월", value: "3M" },
  { label: "최근 6개월", value: "6M" },
];

const dataPurposeList = [
  { label: "잠재고객 식별", value: "잠재고객 식별" },
  { label: "기술 개발", value: "기술 개발" },
  { label: "서비스 고도화", value: "서비스 고도화" },
  { label: "통계자료 수집", value: "통계자료 수집" },
  { label: "마케팅 활용", value: "마케팅 활용" },
];

const titleTypeList = [
  {
    label: "기본형",
    value: "기본형",
  },
  {
    label: "포인트 강조형",
    value: "포인트 강조형",
  },
  {
    label: "프로모션형",
    value: "프로모션형",
  },
  {
    label: "트렌드형",
    value: "트렌드형",
  },
];

const introList = [
  {
    label: "선택안함",
    value: "선택안함",
  },
  {
    label: "선착순",
    value: "선착순",
  },
  {
    label: "이벤트",
    value: "이벤트",
  },
  {
    label: "목표 인원 수",
    value: "목표 인원 수",
  },
];

const transactionBannerList = [
  {
    label: "실사형",
    value: "photo",
  },
  {
    label: "일러스트형",
    value: "illust",
  },
  {
    label: "3D형",
    value: "3d",
  },
];

const bannerImageList = [
  "img_bank_3d",
  "img_health_intg_3d",
  "img_stock_3d",
  "img_bank_illust",
  "img_health_intg_photo",
  "img_stock_illust",
  "img_bank_photo",
  "img_health_photo",
  "img_stock_photo",
  "img_card_3d",
  "img_insu_3d",
  "img_treat_3d",
  "img_card_illust",
  "img_insu_illust",
  "img_treat_illust",
  "img_card_photo",
  "img_insu_photo",
  "img_treat_photo",
  "img_finance_intg_3d",
  "img_life_intg_3d",
  "img_union_3d",
  "img_finance_intg_illust",
  "img_life_intg_illust",
  "img_union_illust",
  "img_finance_intg_photo",
  "img_life_intg_photo",
  "img_union_photo",
  "img_health_3d",
  "img_shopping_3d",
  "img_youtube_3d",
  "img_health_illust",
  "img_shopping_illust",
  "img_youtube_illust",
  "img_health_ingt_illust",
  "img_shopping_photo",
  "img_youtube_photo",
  "img_emr_3d",
  "img_emr_photo",
  "img_exercise_photo",
  "img_exercise_illust",
  "img_exercise2_illust",
];

const marketTitleList = {
  exptype: [
    {
      name: "기본형",
      title: [
        "{$TYPE} 데이터를 거래해 보세요",
        "{$TYPE} 데이터를 안전하게 거래해 보세요",
        "지금 {$TYPE} 데이터 연결하고 거래해 보세요",
      ],
    },
    {
      name: "포인트 강조형",
      title: [
        "지금 {$TYPE} 데이터 거래하면 {$POINT} 포인트가?!",
        "{$TYPE} 데이터 거래하고 {$POINT} 포인트 받아보세요",
        "{$TYPE} 데이터 거래하고 {$POINT} 포인트 받자!",
      ],
    },
    {
      name: "프로모션형",
      title: [
        "데이터를 결합할수록 커지는 포인트!",
        "마이디에서 포인트 받고 알뜰하게 쇼핑해요",
        "데이터 가치의 재발견! 지금 바로 확인해 보세요",
        "포인트가 살랑살랑~ {$TYPE} 데이터 거래해봄~",
        "더워지기 전에 포인트 받고 여름 준비 콜?",
        "포인트가 떨어지는 마이디에서 거래 어떰?",
        "겨울엔 마이디에서 포인트 눈사람 만들어요⛄",
        "[상반기 결산] 올해 {$TYPE} 데이터 확인하고 포인트 받자",
        "[하반기 결산] 올해 {$TYPE} 데이터 확인하고 포인트 받자",
      ],
    },
    {
      name: "트렌드형",
      title: [
        "이제 이 포인트는 제껍니다",
        "앱테크 하는데 마이디를 모른다고?",
        "텅장 말고 통장에 포인트 쌓으세요",
        "자~ 포인트 들어가요~ 따-끔😐",
        "심각한 수준의 데이터 거래 중독입니다",
        "오늘은 {$TYPE} 데이터 거래 Day!",
        "아직도 {$TYPE} 데이터 거래 안한 사람이 있다고?",
      ],
    },
  ],
};

const marketStatusMap: { [key: number]: string[] } = {
  0: ["진행중", "inProgress"],
  1: ["예정", "notStarted"],
  2: ["철회", "revoked"],
  3: ["종료", "ended"],
  4: ["종료", "ended"],
  5: ["임시저장", "temporary"],
};

const serverClientDataCategoryMap: { [key: string]: string } = {
  medicalCheckup: "건강검진",
  medicationRecord: "진료 및 투약",
  patientEMR: "EMR",
  exerciseResult: "운동",
  bankTransaction: "은행 예적금",
  card: "카드",
  securities: "증권",
  loan: "대출",
  insurance: "보험",
  googleYoutubeKeyword: "구글/유튜브",
  shoppingMallOrder: "쇼핑",
};

const translateTargetMap: { [key: string]: string } = {
  ...serverClientDataCategoryMap,
  "건강검진 데이터": "medicalCheckup",
  "병원진료 및 투약 데이터": "medicationRecord",
  "EMR 데이터": "patientEMR",
  "운동 데이터": "exerciseResult",
  "은행(예적금) 데이터": "bankTransaction",
  "카드 데이터": "card",
  "증권 데이터": "securities",
  "보험 데이터": "insurance",
  "Google/Youtube 검색 데이터": "googleYoutubeKeyword",
  "쇼핑 데이터": "shoppingMallOrder",
  예적금: "은행 예적금",
  "의료(병원 진료 기록/투약 이력)": "진료 및 투약",
  "의료(건강검진 내역)": "건강검진",
  "쇼핑몰 구매 내역": "쇼핑",
  "Google/Youtube 검색기록": "구글/유튜브",

  // 임시 하드코딩
  "2w 위치 데이터": "위치",
  "1M 위치 데이터": "위치",
  "3M 위치 데이터": "위치",
  "1M 이동 데이터": "이동",
  "3M 이동 데이터": "이동",
  "1M 이용내역 데이터": "이용내역",
  "3M 이용내역 데이터": "이용내역",
  "6M 이용내역 데이터": "이용내역",
  "2w 구매내역 데이터": "구매내역",
  "1M 구매내역 데이터": "구매내역",
  "3M 구매내역 데이터": "구매내역",
  "6M 구매내역 데이터": "구매내역",
  "3M 리뷰 데이터": "리뷰",
  "6M 리뷰 데이터": "리뷰",
  "1M 접속내역 데이터": "접속내역",
  "3M 접속내역 데이터": "접속내역",
  "6M 접속내역 데이터": "접속내역",
};

export {
  LIMIT_PRICE,
  TARGET_MIN_AGE,
  TARGET_MAX_AGE,
  privacyDataList,
  collectDataList,
  collectPeriodList,
  dataPurposeList,
  genderList,
  mobileList,
  titleTypeList,
  introList,
  transactionBannerList,
  bannerImageList,
  marketTitleList,
  marketStatusMap,
  translateTargetMap,
  serverClientDataCategoryMap,
  marketProducts,
  nonAnonymousMarketProducts,
};
