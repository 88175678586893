import { RadioBox } from "common/input";
import { bannerImageList, transactionBannerList } from "constants/market";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { marketContentAtom } from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionContent/contentBanner.module.scss";
import BannerRadioBox from "./BannerRadioBox";
import { CheckTitle } from "pages/market/marketComponents";

export default function ContentBanner() {
  const [{ banner }, setMarketState] = useRecoilState(marketContentAtom);
  const [bannerType, setBannerType] = useState("photo");

  const handleCheckBox = (value: string) => setBannerType(value);
  const handleBannerImage = (value: string) => setMarketState((prev) => ({ ...prev, banner: value }));

  const isBannerTypeImg = (imgName: string) => imgName.includes(bannerType);
  const filteredBannerImgByBannerType = bannerImageList.filter(isBannerTypeImg);

  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>데이터 거래 배너</p>
      <div className={styles.titleWrapper}>
        <CheckTitle label="이미지타입" height="34px" />
        <RadioBox dataList={transactionBannerList} name="이미지타입" handleCheck={handleCheckBox} value={bannerType} />
      </div>

      <BannerRadioBox
        name="배너이미지"
        value={banner}
        dataList={filteredBannerImgByBannerType}
        handleCheck={handleBannerImage}
      />
    </div>
  );
}
