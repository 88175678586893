import { getMyAccount, postMarketProposal, postProposalStatic } from "api/market";
import queryKey from "constants/queryKey";
import { isNumber } from "lodash";
import useResetMarketState from "pages/market/marketHooks/useResetMarketState";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  marketBudgetAtom,
  marketContentAtom,
  marketDialogAtom,
  marketErrorAtom,
  marketLoadingAtom,
  marketPrivacyAtom,
  marketTargetAtom,
} from "recoils/market";
import { TMarketBudgetData, TMarketContentData, TMarketPrivacyData, TMarketTargetData } from "types/market";
import { TProposalStatic } from "types/proposal";
import { getBannerUrl } from "utils/market";
import { collectData, targetData, targetUser } from "utils/realm/queryGenerators";
import { formatAgeGroup, formatSubmitDate } from "./formatDataHandler";
import formatSubmitData from "./formatSubmitData";
import { validateSubmitData } from "./validateSubmitData";

type TProps = {
  isApplyDidFiltering?: boolean;
};

export default function useMutateProposal(props?: TProps) {
  const setDialogState = useSetRecoilState(marketDialogAtom);
  const setLoadingState = useSetRecoilState(marketLoadingAtom);
  const resetDialogState = useResetRecoilState(marketDialogAtom);
  const setErrorState = useSetRecoilState(marketErrorAtom);
  const targetState = useRecoilValue(marketTargetAtom);
  const budgetState = useRecoilValue(marketBudgetAtom);
  const contentState = useRecoilValue(marketContentAtom);
  const privacyState = useRecoilValue(marketPrivacyAtom);
  const resetMarketState = useResetMarketState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const requestId = Number(searchParams.get("requestId")) || undefined;
  const { isApplyDidFiltering } = props ?? {};

  const { data: accountData } = useQuery(queryKey.GET_MY_ACCOUNT, getMyAccount);

  const { mutate: mutateProposal } = useMutation(postMarketProposal, {
    async onSuccess(data, variables) {
      resetMarketState();
      resetDialogState();

      if (variables.status === "temp") {
        navigate(`/market`);
        return;
      }

      if (variables.status === "charge") {
        navigate(`/reward/charge`);
        return;
      }

      if (variables.status === "register") {
        const param = {
          proposalRequestId: Number(data.data.id),
          proposalStatic: makeProposalStatic({
            targetState,
            budgetState,
            contentState,
            privacyState,
            isApplyDidFiltering,
          }),
        };

        const result = await postProposalStatic(param);

        if (!result.proposalId) {
          navigate(`/market`, { replace: true });
          return;
        }

        navigate(`/market/detail?proposalId=${result.proposalId}`, { replace: true });
      }
    },
    onError: (error) => {
      setErrorState({
        isShow: true,
        error,
        buttonText: "닫기",
      });
      resetDialogState();
    },
    onSettled: () => setLoadingState(false),
  });

  const setProposalDialog = () => {
    if ((accountData?.result || 0) < (budgetState.customReward || budgetState.reward) * budgetState.maxIssuable) {
      setErrorState({
        isShow: true,
        error: Error("포인트 잔고가 부족하여 거래를 등록할 수 없습니다.\n포인트를 확인해 주세요."),
        buttonText: "확인",
      });
      return;
    }
    if (validateSubmitData({ targetState, budgetState, contentState, privacyState })) {
      setDialogState({
        isShow: true,
        btnType: "confirm",
        description: `다음과 같은 데이터가 올바르지 않습니다.\n다시 한번 확인해주세요.\n\n${validateSubmitData({
          targetState,
          budgetState,
          contentState,
          privacyState,
        })}`,
        handleClose: () => setDialogState((prev) => ({ ...prev, isShow: false })),
      });
      return;
    }

    setDialogState({
      isShow: true,
      title: "데이터 거래를 등록하시겠습니까?",
      btnType: "submit",
      description:
        "등록 시 데이터거래가 바로 마이디 앱에 노출됩니다.\n미리보기를 통해 진행하려는 데이터 거래가 맞는지 확인해주세요.",
      handleSubmit: async () => {
        setLoadingState(true);
        const submitData = formatSubmitData({
          targetState,
          budgetState,
          contentState,
          privacyState,
          type: "register",
          requestId,
          hidden: Boolean(isApplyDidFiltering),
        });

        mutateProposal(submitData);
      },
      handleClose: () => setDialogState((prev) => ({ ...prev, isShow: false })),
    });
  };

  const setTempProposalDialog = () => {
    setDialogState({
      isShow: true,
      title: "임시저장 하시겠습니까?",
      btnType: "submit",
      description: "등록 페이지를 나갑니다.\n지금까지 입력된 정보를 임시저장 하시겠습니까?",
      handleSubmit: async () => {
        const directInputTitleResetCondition =
          contentState.titleType === "" && contentState.intro === ""
            ? { ...contentState, transactionName: "" }
            : contentState;

        setLoadingState(true);
        mutateProposal(
          formatSubmitData({
            targetState,
            budgetState,
            contentState: directInputTitleResetCondition,
            privacyState,
            type: "temp",
            requestId,
            hidden: Boolean(isApplyDidFiltering),
          })
        );
      },
      handleClose: () => {
        navigate("/market");
        resetDialogState();
      },
    });
  };

  return { setProposalDialog, setTempProposalDialog, mutateProposal };
}

type TProposalStaticParam = {
  targetState: TMarketTargetData;
  budgetState: TMarketBudgetData;
  contentState: TMarketContentData;
  privacyState: TMarketPrivacyData;
  isApplyDidFiltering?: boolean;
};

function makeProposalStatic({
  targetState,
  budgetState,
  contentState,
  privacyState,
  isApplyDidFiltering,
}: TProposalStaticParam) {
  const keyArr = [...targetState.collectData, "userInfo"];
  const queryData = {
    notBefore: formatSubmitDate(budgetState)[0],
    notAfter: formatSubmitDate(budgetState)[1],
    gender: targetState.gender,
    ageGroup: formatAgeGroup(targetState.age),
    regionOfResidence: targetState.area.map((area) => area.replace(/\s/g, "-")).join(","),
    term: targetState.collectPeriod,
  };

  const filter = targetUser(keyArr, queryData);
  const condition = targetData(keyArr, queryData);
  const select = collectData(keyArr, queryData);

  const category = condition.map((item) => item.category);

  const proposalStatic: TProposalStatic = {
    proposalId: "",
    proposalAddress: "",
    owner: "", // company did
    purpose: 100, // 목적: 데이터 거래 100번
    notBefore: queryData.notBefore,
    notAfter: queryData.notAfter,
    hidden: Boolean(isApplyDidFiltering),
    category,
    condition: JSON.stringify(condition), // 데이터 타켓팅
    filter: JSON.stringify(filter), // 사용자 타켓팅
    content: {
      contentDescription: {
        detail: targetState.dataPurpose.join(","),
        title: contentState.transactionName,
      },
      contentImageUrl: {
        banner: getBannerUrl(contentState.banner), // 배너 이미지
      },
      contentLdpTarget: [],
      contentPurchase: {
        category,
        maskingOptions: [0],
        selectQuery: JSON.stringify(select), // 데이터 수집
        selectRange: queryData.term, // 데이터 수집 기간
      },
      contentPrivacyPolicy: {
        identifier: privacyState.personalIdentification === "nonAnonymous" ? 1 : 0, // 익명 거래 or 실명 거래
        thirdParty:
          privacyState.personalIdentification === "nonAnonymous"
            ? {
                processing: privacyState.thirdPartyStatus,
                name: privacyState.company,
                purpose: privacyState.provisionPurpose.join(","),
              }
            : undefined, // 실명 거래이면 제3자 제공여부, 제공기업, 제공 목적
      },
    },
    maxIssuable: budgetState.maxIssuable, // 목표 인원
    maxConsumeCount: 1,
    rewardPolicy: {
      amount: isNumber(budgetState.customReward) ? budgetState.customReward : budgetState.reward, // 1인당 지급 포인트
      policyType: 2,
      tokenDuration: "12m",
      marginRate: 0,
    },
    ticketValidUntil: queryData.notAfter,
  };

  return proposalStatic;
}
