import { CheckInput, RadioBox } from "common/input";
import { ChangeEventHandler, useEffect } from "react";
import { useRecoilState } from "recoil";
import { marketPrivacyAtom } from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionTargetData/collectPersonalInfo.module.scss";

const dataPurpose = [
  { label: "이벤트 행사", value: "이벤트 행사" },
  { label: "포인트 지급", value: "포인트 지급" },
  { label: "상품 및 서비스 홍보 또는 안내", value: "상품 및 서비스 홍보 또는 안내" },
];

export default function CollectPersonalInfo() {
  const [
    { personalIdentification: identify, thirdPartyStatus, company, provisionPurpose: purposeState },
    setPrivacyState,
  ] = useRecoilState(marketPrivacyAtom);

  const handlePrivacyCheck = (target: "anonymous" | "nonAnonymous") => {
    setPrivacyState((prev) => ({ ...prev, personalIdentification: target }));
    target === "anonymous" && setPrivacyState((prev) => ({ ...prev, thirdPartyStatus: 0 }));
  };

  const handleThirdPartyCheck = (target: "0" | "1") => {
    const numberTarget = Number(target) as 0 | 1;
    setPrivacyState((prev) => ({ ...prev, thirdPartyStatus: numberTarget }));
  };

  const handleProvideCompany: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPrivacyState((prev) => ({ ...prev, company: e.target.value }));
  };

  const handlePurposeCheck = (target: string) => {
    purposeState.includes(target)
      ? setPrivacyState((prev) => ({
          ...prev,
          provisionPurpose: purposeState.filter((data) => data !== target),
        }))
      : setPrivacyState((prev) => ({ ...prev, provisionPurpose: purposeState.concat(target) }));
  };

  useEffect(() => {
    if (thirdPartyStatus === 0) {
      setPrivacyState((prev) => ({ ...prev, company: "" }));
      setPrivacyState((prev) => ({ ...prev, provisionPurpose: [] }));
    }
  }, [setPrivacyState, thirdPartyStatus]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>개인식별정보 활용</p>
      <div className={styles.list}>
        <div className={styles.label}>식별 여부</div>
        <RadioBox
          dataList={[
            { label: "익명 데이터 거래", value: "anonymous" },
            { label: "실명 데이터 거래", value: "nonAnonymous" },
          ]}
          handleCheck={handlePrivacyCheck}
          name="식별 여부"
          value={identify}
        />
      </div>

      {identify === "nonAnonymous" && (
        <>
          <div className={styles.list}>
            <div className={styles.label}>제3자 제공 여부</div>
            <RadioBox
              dataList={[
                { label: "미제공", value: 0 },
                { label: "제공", value: 1 },
              ]}
              handleCheck={handleThirdPartyCheck}
              name="제3자 제공 여부"
              value={thirdPartyStatus}
            />
          </div>

          {thirdPartyStatus === 1 && (
            <>
              <div className={styles.list}>
                <div className={styles.label}>제공 기업</div>
                <div className={styles.inputWrapper}>
                  <input type="text" onChange={handleProvideCompany} value={company} />
                </div>
              </div>

              <div className={styles.list}>
                <div className={styles.label}>제공 목적</div>
                {dataPurpose.map(({ label, value }) => (
                  <CheckInput
                    handleCheck={() => handlePurposeCheck(value)}
                    name={`${label}-provision`}
                    text={label}
                    value={purposeState.includes(value)}
                    key={value}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
