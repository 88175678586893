import { TMarketProposalData } from "types/market";
import PreviewInfo from "./PreviewInfo";
import PreviewCard from "./PreviewCard";
import dayjs from "dayjs";
import { excludeExtraDataCategory } from "utils/market";
import { marketProducts, translateTargetMap } from "constants/market";

type TProps = {
  proposalInfo: TMarketProposalData;
};

export default function DetailPreviewSidebar({ proposalInfo }: TProps) {
  const { proposalRequest, proposal } = proposalInfo;
  const cardProps = {
    bannerImageCdn: proposalRequest.contentObject.imageURL.banner,
    title: proposalRequest.title,
    rewardPolicyAmount: proposalRequest.rewardPolicyAmount,
    maxIssuable: proposalRequest.maxIssuable,
    collectTarget: excludeExtraDataCategory(proposalRequest.selectData),
    // @TODO: 추후 privacy Data를 받아서 사용하도록 수정
    privacyState: {
      personalIdentification: proposalRequest.contentObject?.privacyPolicy?.identifier
        ? ("nonAnonymous" as "nonAnonymous")
        : ("anonymous" as "anonymous"),
    },
  };

  const infoProps = {
    rewardPolicyAmount: proposalRequest.rewardPolicyAmount,
    maxIssuable: proposalRequest.maxIssuable,
    collectTarget: excludeExtraDataCategory(proposalRequest.selectData).map((v) => translateTargetMap[v] || v),
    dataPurpose: proposalRequest.contentObject.description.detail?.split(","),
    startDate: proposal.notBefore,
    endDate: dayjs(proposal.notAfter).format("YYYY-MM-DDTHH:mm:ssZ"),
    collectPeriod: proposalRequest.contentObject.purchase.selectRange,
    // @TODO: 추후 privacy Data를 받아서 사용하도록 수정
    privacyState: {
      personalIdentification: proposalRequest.contentObject?.privacyPolicy?.identifier
        ? ("nonAnonymous" as "nonAnonymous")
        : ("anonymous" as "anonymous"),
      thirdPartyStatus: (proposalRequest.contentObject?.privacyPolicy?.thirdParty?.processing || 0) as 0 | 1,
      company: proposalRequest.contentObject?.privacyPolicy?.thirdParty?.name || "",
      provisionPurpose: proposalRequest.contentObject?.privacyPolicy?.thirdParty?.purpose?.split(",") || [],
    },
    product: marketProducts[0],
  };

  return (
    <>
      <PreviewCard cardData={cardProps} />
      <PreviewInfo infoData={infoProps} />
    </>
  );
}
